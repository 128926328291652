import { Fragment, useState, useEffect } from "react";
import axios from "axios";
import domtoimage from "dom-to-image";
import confetti from "canvas-confetti";
import "./App.css";

function App() {
  const [input, setInput] = useState("");
  const [data, setData] = useState(null);
  const [isPunk, setIsPunk] = useState(true);
  const [loading, setLoading] = useState(false);

  const punkAddress = "0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb";
  const apeAddress = "0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d";

  const inactiveProjectClasses =
    "bg-white border border-gray-200 hover:bg-gray-50";
  const activeProjectClasses = "bg-ml text-white";

  function returnAssetURL() {
    let finalURL;
    if (isPunk) {
      finalURL = `https://api.opensea.io/api/v1/asset/${punkAddress}/${input}/`;
    } else {
      finalURL = `https://api.opensea.io/api/v1/asset/${apeAddress}/${input}/`;
    }

    return finalURL;
  }

  useEffect(() => {
    setInput("");
  }, [isPunk]);

  useEffect(() => {
    if (data === null) {
      return;
    }

    console.log("create png was called");
    createPng();
  }, [data]);

  async function handleClick() {
    // reset the data to be safe
    setData(null);

    // start the loading state
    setLoading(true);

    // Determine the opensea asset url
    const url = returnAssetURL();

    // Call opensea for the asset
    await axios
      .get(url)
      .then(async function (response) {
        // handle success
        console.log(response);
        // Update the state with the opensea response
        setData(response.data);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  function createPng() {
    // The element to be converted to an image
    var node = document.getElementById("node");

    var imageContainer = document.getElementById("imageContainer");

    domtoimage
      .toPng(node)
      .then(async function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        img.id = "processedImg";
        img.classNames = "z-50 absolute image-size top-0 left-0";
        var oldImg = document.getElementById("processedImg");
        if (oldImg) {
          await imageContainer.removeChild(oldImg);
        }
        imageContainer.appendChild(img);
        await setLoading(false);

        fireworks();
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
      });
  }

  function fireworks() {
    var duration = 3 * 1000;
    var animationEnd = Date.now() + duration;
    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    var interval = setInterval(function () {
      var timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      var particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        })
      );
    }, 250);
  }

  function DomMergedImageRepresentation() {
    return (
      <Fragment>
        <div className="bg-white image-size z-10 absolute top-0 left-0 opacity-60"></div>
        <div id="node" className="image-size absolute top-0 left-0">
          {data ? (
            <div id="placeholder">
              <img
                alt="metalinkified"
                className="image-size absolute top-0 left-0"
                src={data.image_url}
              ></img>
              {isPunk ? (
                <img
                  alt="metalinkified"
                  className="image-size absolute top-0 left-0"
                  src="./punk-mouth.png"
                ></img>
              ) : (
                <img
                  alt="metalinkified"
                  className="image-size absolute top-0 left-0"
                  src="./ape-mouth.png"
                ></img>
              )}
            </div>
          ) : null}
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="header px-4 bg-white border-b border-gray-200 py-2">
        <div className="flex items-center">
          <a href="/">
            <img width="44" alt="metalink logo" src={"ml-logo.png"} />
          </a>
          <span className="ml-1 font-semibold tracking-tight text-lg">
            Metalinkify your NFT
          </span>
        </div>
      </div>
      <div className="mx-auto max-ml px-4 mt-12">
        <h1 className="text-2xl text-center font-semibold tracking-tight mb-4">
          Choose your poison
        </h1>
        <div className="flex justify-evenly mb-4">
          <button
            className={`flex justify-center items-center font-medium tracking-tight shadow-sm flex-grow mr-2 text-center py-3 rounded-md ${
              isPunk ? activeProjectClasses : inactiveProjectClasses
            }`}
            onClick={() => setIsPunk(true)}
          >
            <img
              className="w-8 h-8 mr-2 border-2 border-white rounded-full"
              src={"./group-cryptopunks.png"}
              alt="punk logo"
            />
            Punks
          </button>
          <button
            className={`flex justify-center items-center font-medium tracking-tight shadow-sm flex-grow ml-2 text-center py-3 rounded-md ${
              isPunk ? inactiveProjectClasses : activeProjectClasses
            }`}
            onClick={() => setIsPunk(false)}
          >
            <img
              className="w-8 h-8 mr-2 border-2 border-white rounded-full"
              src={"./group-bayc.png"}
              alt="ape logo"
            />
            Apes
          </button>
        </div>
        <div className="flex flex-col sm:flex-row text-center mb-4">
          <input
            className="border p-3 flex-grow rounded-xl  text-sm"
            placeholder={`Enter your ${
              isPunk ? "punk" : "bored ape"
            } number...`}
            value={input}
            type="number"
            onChange={(e) => setInput(e.target.value)}
          />
          <button
            onClick={handleClick}
            className="sm:ml-2 mt-4  sm:mt-0 flex justify-center items-center bg-ml hover:scale-105 transform transition font-semibold text-white py-3 text-sm px-6 rounded-xl"
          >
            Metalinkify {loading ? <div className="ml-2 loader"></div> : null}
          </button>
        </div>

        <div
          id="imageContainer"
          className={`bg-gray-100 mx-auto image-size border-gray-200 border relative rounded-3xl overflow-hidden`}
        >
          {/* Final image will be appended here */}
          {loading ? (
            <Fragment>
              <p className="font-bold text-gray-500 text-center z-30 absolute w-full text-center top-10 left-0">
                Generating...
              </p>
              <DomMergedImageRepresentation />
            </Fragment>
          ) : null}
        </div>

        <p className="text-center text-sm mt-3">
          Right click <span className="font-medium">"Save Image As..."</span>
        </p>
      </div>
    </Fragment>
  );
}

export default App;
